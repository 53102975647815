<script setup>
import { computed, ref } from 'vue'
import { t } from '@/i18n'
import { useRoute } from 'vue-router'

const route = useRoute()

const tab_list = ref([
    {title: t('首页'), path: '/', icon: require('@/assets/img/footer-icon-home.svg'), selectIcon: require('@/assets/img/footer-icon-home-a.svg')},
    {title: t('预订'), path: '/task', icon: require('@/assets/img/footer-icon-reserve.svg'), selectIcon: require('@/assets/img/footer-icon-reserve-a.svg')},
    {title: t('客服2'), path: '/service', icon: require('@/assets/img/footer-icon-service.svg'), selectIcon: require('@/assets/img/footer-icon-service-a.svg')},
    {title: t('我的'), path: '/user', icon: require('@/assets/img/footer-icon-mine.svg'), selectIcon: require('@/assets/img/footer-icon-mine-a.svg')},
])

const current_path = computed(()=> {
    return route.path
})
</script>

<template>
    <footer class="ctx-wrap bg-default text-default border-t border-black/30 fixed left-1/2 bottom-0 -translate-x-1/2 z-40">
        <div class="ctx-wrap h-[60px] flex">
            <template v-for="(item,index) in tab_list" :key="index">
                <router-link :to="item.path" :class="item.path==current_path?'text-theme':''" class="w-1/3 h-full px-1 flex flex-col items-center justify-center">
                    <img :src="item.path==current_path?item.selectIcon:item.icon" :class="index==2?'h-8 mb-1':'h-9'" class="w-auto max-w-full object-contain block">
                    <p class="text-xs text-center">{{item.title}}</p>
                </router-link>
            </template>
        </div>
        <em class="w-full h-safe"></em>
    </footer>
    <em class="w-full pt-[60px] block">
        <i class="w-full h-safe"></i>
    </em>
</template>