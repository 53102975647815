import { createApp, reactive } from 'vue'
import widget from './loading.vue'
var params = reactive({
    show: false,
    title: ''
})

const dom = createApp(widget, {params}).mount(document.createElement("div"))
var $loading = {
    show(title) {
        params.show = true
        params.title = title||''
        document.body.appendChild(dom.$el)
    },
    hide() {
        params.show = false
    }
}
export default $loading