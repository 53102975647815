export default {
    "title": "🇩🇪 Deutsch",

    "很棒的住宿体验": "Ein großartiger Ort zum Wandern mit vielen sehenswerten Orten.",
    "清爽干净，维护良好": "Ein großartiger Ort für Urlaub und Entspannung!",
    "很棒的酒店和员工": "Dies ist eine andere Erfahrung und es fühlt sich großartig an.",
    "价值符合预期": "Wert wie erwartet",
    "卓越的": "Außergewöhnlich",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Schönes Land, ein großartiger Ort für ein Wochenendausflug. Interessanter Ort.",

    "首页": "Startseite",
    "预订": "BUCHEN",
    "关于": "Über uns",
    "发现": "Entdecken",
    "会员": "Mitgliedschaft",
    "推荐计划": "Empfehlungsprogramm",
    "个人": "Profil",
    "我的": "Mein",
    "推荐": "Empfehlung",
    "事件": "Ereignis",
    "登出": "Abmelden",
    "登录": "Anmelden",
    "注册": "Registrieren",
    "语言": "Sprache",
    "忘记密码": "Passwort vergessen",
    "取消": "Abbrechen",
    "确认": "Bestätigen",
    "酒店": "Spotlight-Ziele",
    "银行卡列表": "Bankkartenliste",
    "银行列表": "Bankliste",
    "持卡人姓名": "Karteninhabername",
    "银行卡号": "Bankkonto",
    "支付密码": "Zahlungspasswort",
    "提交": "Einreichen",
    "Pix列表": "Pix-Liste",
    "Pix类型": "Pix-Typ",
    "Pix账号": "Pix-Konto",
    "账号列表": "Kontoliste",
    "网络": "Netzwerk",
    "地址": "Adresse",
    "备注": "Bemerkung",
    "充值金额": "Aufladebetrag",
    "支付类型": "Zahlungsart",
    "支付银行": "Zahlungs-Bank",
    "USDT地址": "USDT-Adresse",
    "二维码": "QR-Code",
    "充值渠道": "Aufladekanal",
    "充值凭证": "Aufladebeleg",
    "立即登录": "Jetzt anmelden",
    "用户名": "Benutzername",
    "密码": "Passwort",
    "邀请码": "Einladungscode",
    "确认密码": "Passwort bestätigen",
    "手机": "Handy",
    "金额": "Betrag",
    "新增": "Neu",
    "钱包余额": "Kontostand",
    "单价": "Preis",
    "数量": "Anzahl",
    "总额": "Gesamt",
    "奖励": "Belohnungen",
    "手机号码": "Telefonnummer",
    "修改密码": "Passwort ändern",
    "登录密码": "Anmeldepasswort",
    "当前密码": "Aktuelles Passwort",
    "新密码": "Neues Passwort",
    "确认新密码": "Neues Passwort bestätigen",
    "保存修改": "Änderungen speichern",
    "充值": "Aufladen",
    "复制": "Kopieren",
    "分享链接": "Link teilen",
    "升级": "Upgrade",
    "登录时间": "Anmeldezeit",
    "信誉分": "Glaubwürdigkeit",
    "提现": "Abheben",
    "提现账号": "Abhebekonto",
    "你确定要删除此账号吗": "Sind Sie sicher, dass Sie dieses Konto löschen möchten?",
    "你确定要删除此地址吗": "Sind Sie sicher, dass Sie diese Adresse löschen möchten?",
    "提示": "Hinweis",
    "是": "Ja",
    "否": "Nein",
    "全部": "Alle",
    "待处理": "Ausstehend",
    "冻结中": "Gesperrt",
    "已完成": "Abgeschlossen",
    "我们的伙伴": "Unsere Partner",
    "关于我们": "Über uns",
    "创建时间": "Erstellungszeit",
    "你确定要登出当前账号吗？": "Sind Sie sicher, dass Sie sich vom aktuellen Konto abmelden möchten?",
    "正在寻找酒店": "Suche nach einem Hotel",
    "旅程价格": "Reisepreis",
    "佣金": "Provisionen",
    "搜索": "Suche",
    "评分": "Bewertung",
    "评论": "Kommentar",
    "评论-t2": "Bewertung",
    "你还没有设置支付密码，请前往设置": "Sie haben noch kein Zahlungspasswort festgelegt. Bitte gehen Sie zu den Einstellungen",
    "请输入": "Bitte eingeben",
    "请选择": "Bitte auswählen",
    "账户明细": "Kontodetails",
    "等级": "Level",
    "每日订单": "Tägliche Bestellungen",
    "设置": "Einstellungen",
    "语言设置": "Spracheinstellungen",
    "客服": "Kundenservice",
    "客服2": "Service",
    "投诉": "Beschwerden",
    "投诉客户服务": "Beschwerde über den Kundenservice",
    "帮助": "Hilfe",
    "复制成功": "Erfolgreich kopiert",
    "位置、中心": "Lage, Zentrum",
    "你好": "Hallo",
    "我的账户": "Mein Konto",
    "头像": "Avatar",
    "昵称": "Spitzname",
    "设置昵称": "Spitznamen einstellen",
    "输入酒店": "Avenue eingeben",
    "住宿问答": "Bewertungen",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Schauen Sie sich unsere authentischen Bewertungen von unseren Benutzern an, um mehr über die Unterkunft zu erfahren.",
    "酒店问答_Q1": "Was ist der Unterschied zwischen einem Doppelzimmer und einem Zweibettzimmer?",
    "酒店问答_A1": "Ein Doppelzimmer hat ein Doppelbett und ein Zweibettzimmer hat 2 Einzelbetten. Wenn ein Zimmer als Doppel-/Zweibettzimmer bezeichnet wird, kann es für beide Typen eingerichtet werden. War diese Information hilfreich?",
    "酒店问答_Q2": "Was ist im Preis inbegriffen?",
    "酒店问答_A2": "Sie können überprüfen, ob weitere Gebühren wie Frühstück, Steuern oder Servicegebühren enthalten sind. Diese Informationen finden Sie auch in Ihrer Bestätigungs-E-Mail nach der Buchung, und Sie können Ihre Reservierung auch in Ihrem Konto anzeigen.",
    "立即预订可享受酒店特别折扣": "Jetzt erkunden und Sonderangebote genießen",
    "最低": "MIN",
    "折扣": "RABATT",
    "订单时间": "Bestellzeit",
    "产品": "Produkt",
    "订单数量": "Bestellmenge",
    "提交订单": "Bestellung abschicken",
    "可用余额": "Freier Betrag",
    "冻结金额": "Gesperrter Betrag",
    "佣金比例": "Provisionssatz",
    "获得佣金": "Provision verdienen",
    "完成订单": "Bestellung abschließen",
    "请至少选择一项": "Bitte wählen Sie mindestens eine Option aus",
    "请先选择星星": "Bitte wählen Sie zuerst den Stern aus",
    "公告": "Ankündigung",
    "邀请好友": "Freunde einladen",
    "邀请好友赚取佣金": "Laden Sie Freunde ein und verdienen Sie Provision",
    "条款&协议": "Bedingungen & Vereinbarungen",
    "注册协议": "Registrierungsvereinbarung",
    "我已阅读并同意": "Ich habe gelesen und stimme zu",
    "加载中...": "Wird geladen...",
    "没有更多数据": "Keine weiteren Daten",
    "信息": "Nachricht",
    "快乐的客户": "Zufriedener Kunde",
    "快乐的酒店老板": "Zufriedener Hotelbesitzer",
    "评论1": "Erstaunliche Plattform mit großartigen Funktionen, die es wirklich wert ist, erkundet zu werden!",
    "评论2": "Proaktiver Kundenservice, der Sie durch die Plattform führt, wenn Sie unklar sind.",
    "评论3": "Vertrauenswürdige Plattform, die es wert ist, ausprobiert zu werden!",
    "评论4": "Ich konnte mein Hotel hier auflisten und großartige Marketingergebnisse erzielen.",
    "客户评价": "Kundenbewertungen",
    "查看我们用户的真实评价。": "Schauen Sie sich echte Bewertungen von unseren Benutzern an.",
    "必须五星才能提交订单": "Es müssen fünf Sterne vorhanden sein, um eine Bestellung aufzugeben",
}