export default {
    "title": "🇵🇱 Polski",

    "很棒的住宿体验": "To wspaniałe miejsce do wędrówek, z wieloma miejscami warte zobaczenia. ",
    "清爽干净，维护良好": "Wspaniałe miejsce na wakacje i relaks! ",
    "很棒的酒店和员工": "To inna, wspaniała, doświadczenie",
    "价值符合预期": "Wartość zgodna z oczekiwaniami",
    "卓越的": "Wyjątkowy",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Piękna ziemia, wspaniałe miejsce na weekendowy wypad. Ciekawe miejsce",

    "首页": "Strona główna",
    "预订": "REZERWUJ",
    "关于": "O nas",
    "发现": "Odkryj",
    "会员": "Członkostwo",
    "推荐计划": "Program poleceń",
    "个人": "Profil",
    "我的": "Moje",
    "推荐": "Polecenie",
    "事件": "Wydarzenie",
    "登出": "Wyloguj",
    "登录": "Zaloguj się",
    "注册": "Zarejestruj się",
    "语言": "Język",
    "忘记密码": "Zapomniane",
    "取消": "Anuluj",
    "确认": "Potwierdź",
    "酒店": "Najważniejsze destynacje",
    "银行卡列表": "Lista kart bankowych",
    "银行列表": "Lista banków",
    "持卡人姓名": "Imię i nazwisko posiadacza karty",
    "银行卡号": "Numer konta bankowego",
    "支付密码": "Hasło płatności",
    "提交": "Zatwierdź",
    "Pix列表": "Lista Pix",
    "Pix类型": "Typ Pix",
    "Pix账号": "Konto Pix",
    "账号列表": "Lista kont",
    "网络": "Sieć",
    "地址": "Adres",
    "备注": "Uwagi",
    "充值金额": "Kwota doładowania",
    "支付类型": "Typ płatności",
    "支付银行": "Bank płatności",
    "USDT地址": "Adres USDT",
    "二维码": "Kod QR",
    "充值渠道": "Kanał doładowania",
    "充值凭证": "Potwierdzenie doładowania",
    "立即登录": "Zaloguj się teraz",
    "用户名": "Nazwa użytkownika",
    "密码": "Hasło",
    "邀请码": "Kod zaproszenia",
    "确认密码": "Potwierdź hasło",
    "手机": "Telefon",
    "金额": "Kwota",
    "新增": "Nowy",
    "钱包余额": "Saldo konta",
    "单价": "Cena jednostkowa",
    "数量": "Ilość",
    "总额": "Suma",
    "奖励": "Nagrody",
    "手机号码": "Numer telefonu",
    "修改密码": "Zmień hasło",
    "登录密码": "Hasło logowania",
    "当前密码": "Obecne hasło",
    "新密码": "Nowe hasło",
    "确认新密码": "Potwierdź nowe hasło",
    "保存修改": "Zapisz zmiany",
    "充值": "Doładowanie",
    "复制": "Kopiuj",
    "分享链接": "Udostępnij link",
    "升级": "Ulepsz",
    "登录时间": "Czas logowania",
    "信誉分": "Wiarygodność",
    "提现": "Wypłata",
    "提现账号": "Konto wypłat",
    "你确定要删除此账号吗": "Czy na pewno chcesz usunąć to konto?",
    "你确定要删除此地址吗": "Czy na pewno chcesz usunąć ten adres?",
    "提示": "Wskazówka",
    "是": "Tak",
    "否": "Nie",
    "全部": "Wszystko",
    "待处理": "Oczekujące",
    "冻结中": "Zamrożone",
    "已完成": "Zakończone",
    "我们的伙伴": "Nasi partnerzy",
    "关于我们": "O nas",
    "创建时间": "Czas utworzenia",
    "你确定要登出当前账号吗？": "Czy na pewno chcesz wylogować się z obecnego konta?",
    "正在寻找酒店": "Szukam hotelu",
    "旅程价格": "Cena podróży",
    "佣金": "Prowizja",
    "搜索": "Szukaj",
    "评分": "Ocena",
    "评论": "Komentarz",
    "评论-t2": "Recenzja",
    "你还没有设置支付密码，请前往设置": "Nie ustawiłeś jeszcze hasła płatności, przejdź do ustawień",
    "请输入": "Wpisz tutaj",
    "请选择": "Wybierz tutaj",
    "账户明细": "Szczegóły konta",
    "等级": "Poziom",
    "每日订单": "Dziennie zamówień",
    "设置": "Ustawienia",
    "语言设置": "Ustawienia języka",
    "客服": "Obsługa klienta",
    "客服2": "Obsługa",
    "投诉": "Skarga",
    "投诉客户服务": "Zgłoś skargę na obsługę klienta",
    "帮助": "Pomoc",
    "复制成功": "Skopiowano pomyślnie",
    "位置、中心": "Lokalizacja, centrum",
    "你好": "Cześć",
    "我的账户": "Moje konto",
    "头像": "Avatar",
    "昵称": "Nick",
    "设置昵称": "Ustaw pseudonim",
    "输入酒店": "Wprowadź adres hotelu",
    "住宿问答": "Opinie",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Sprawdź autentyczne opinie naszych użytkowników.",
    "酒店问答_Q1": "Jaka jest różnica między pokojem Double a Twin?",
    "酒店问答_A1": "Pokój Double ma jedno podwójne łóżko, a pokój Twin ma 2 pojedyncze łóżka. Jeśli pokój jest oznaczony jako Double/Twin, można go przygotować na oba sposoby. Czy ta informacja była pomocna?",
    "酒店问答_Q2": "Co jest wliczone w cenę?",
    "酒店问答_A2": "Możesz sprawdzić, czy w cenie są uwzględnione dodatkowe opłaty, takie jak śniadanie, podatki lub opłaty serwisowe. Te informacje znajdziesz również w potwierdzeniu rezerwacji, które otrzymasz po dokonaniu rezerwacji, a także w swoim koncie.",
    "立即预订可享受酒店特别折扣": "Odkryj teraz z pewnością siebie",
    "最低": "MIN",
    "折扣": "ZNIŻKA",
    "订单时间": "Czas zamówienia",
    "产品": "Produkt",
    "订单数量": "Liczba zamówień",
    "提交订单": "Złóż zamówienie",
    "可用余额": "Dostępne saldo",
    "冻结金额": "Zamrożona kwota",
    "佣金比例": "Stawka prowizji",
    "获得佣金": "Zarobiona prowizja",
    "完成订单": "Zakończ zamówienie",
    "请至少选择一项": "Wybierz co najmniej jedną opcję",
    "请先选择星星": "Najpierw wybierz gwiazdkę",
    "公告": "Ogłoszenie",
    "邀请好友": "Zaproś przyjaciół",
    "邀请好友赚取佣金": "Zaproś przyjaciół i zarabiaj prowizję",
    "条款&协议": "Warunki i umowy",
    "注册协议": "Umowa rejestracyjna",
    "我已阅读并同意": "Przeczytałem/am i zgadzam się",
    "加载中...": "Ładowanie...",
    "没有更多数据": "Brak więcej danych",
    "信息": "Wiadomość",
    "快乐的客户": "Zadowolony klient",
    "快乐的酒店老板": "Zadowolony właściciel hotelu",
    "评论1": "Niesamowita platforma z wspaniałymi funkcjami, naprawdę warto ją poznać!",
    "评论2": "Proaktywna obsługa klienta, która przeprowadzi Cię przez platformę, jeśli nie jesteś pewien/a, jak z niej korzystać.",
    "评论3": "Zaufana platforma, warta spróbowania!",
    "评论4": "Udało mi się umieścić mój hotel tutaj i osiągnąć świetne wyniki marketingowe.",
    "客户评价": "Opinie klientów",
    "查看我们用户的真实评价。": "Sprawdź prawdziwe opinie naszych użytkowników.",
    "必须五星才能提交订单": "Aby złożyć zamówienie, musisz mieć pięć gwiazdek",
}