import { createI18n } from 'vue-i18n'
import { useLocalStorage } from '@vueuse/core'
import zhCN from './language/zh-ch'
import en from './language/en'
import ja from './language/ja'
import fr from './language/fr'
import nl from './language/nl'
import vi from './language/vi'
import th from './language/th'
import es from './language/es'
import pl from './language/pl'
import it from './language/it'
import dk from './language/dk'
import de from './language/de'
import sa from './language/sa'

/**
 * Amount formatting
 */
export function formatAmountFunc(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}


const langRef = useLocalStorage('language', 'dk')

const messages = { dk,sa,de, es,pl,it,en,fr,nl,ja,vi,th,'zh-CN': zhCN }

const i18n = createI18n({ locale: langRef.value, messages })

const languageList = Object.keys(messages).map(k =>
  ({ label: messages[k].title, value: k })
)

const setLanguage = (item = { label: '', value: '' }) => {
  i18n.locale = item.value
  langRef.value = item.value
  setTimeout(() => {
    location.reload()
  }, 500);
}

const t = i18n.global.t

const moneyFlag = process.env.VUE_APP_MONEY_FLAG

const fm = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return `${moneyFlag} ${_val}`
}

const fn = (money) => {
  let _val = formatAmountFunc(parseFloat(money||0).toFixed(2))
  return _val
}

export default i18n
export { t, langRef, languageList, setLanguage, fm, fn }
