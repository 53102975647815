export default {
    "title": "🇮🇹 Italiano",

    "很棒的住宿体验": "Questo è un ottimo posto dove passeggiare, con molti luoghi interessanti da vedere.",
    "清爽干净，维护良好": "Un ottimo posto per le vacanze e il relax!",
    "很棒的酒店和员工": "Questa è un'esperienza diversa e si sente fantastico",
    "价值符合预期": "Valore come previsto",
    "卓越的": "Eccezionale",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Bella terra, un ottimo posto per una vacanza weekend. Luogo interessante",

    "首页": "Home",
    "预订": "PRENOTA",
    "关于": "Chi siamo",
    "发现": "Scopri",
    "会员": "Membri",
    "推荐计划": "Programma di riferimento",
    "个人": "Profilo",
    "我的": "Mio",
    "推荐": "Riferimento",
    "事件": "Evento",
    "登出": "Esci",
    "登录": "Accedi",
    "注册": "Registrati",
    "语言": "Lingua",
    "忘记密码": "Dimenticato",
    "取消": "Annulla",
    "确认": "Conferma",
    "酒店": "Spotlight Destinazioni",
    "银行卡列表": "Elenco carte bancarie",
    "银行列表": "Elenco banche",
    "持卡人姓名": "Nome del titolare della carta",
    "银行卡号": "Numero di conto bancario",
    "支付密码": "Password di pagamento",
    "提交": "Invia",
    "Pix列表": "Elenco Pix",
    "Pix类型": "Tipo di Pix",
    "Pix账号": "Conto Pix",
    "账号列表": "Elenco account",
    "网络": "Rete",
    "地址": "Indirizzo",
    "备注": "Nota",
    "充值金额": "Importo di ricarica",
    "支付类型": "Tipo di pagamento",
    "支付银行": "Banca di pagamento",
    "USDT地址": "Indirizzo USDT",
    "二维码": "Codice QR",
    "充值渠道": "Canale di ricarica",
    "充值凭证": "Voucher di ricarica",
    "立即登录": "Accedi subito",
    "用户名": "Nome utente",
    "密码": "Password",
    "邀请码": "Codice invito",
    "确认密码": "Conferma password",
    "手机": "Cellulare",
    "金额": "Importo",
    "新增": "Nuovo",
    "钱包余额": "Saldo del conto",
    "单价": "Prezzo unitario",
    "数量": "Quantità",
    "总额": "Totale",
    "奖励": "Ricompense",
    "手机号码": "Numero di telefono",
    "修改密码": "Modifica password",
    "登录密码": "Password di accesso",
    "当前密码": "Password attuale",
    "新密码": "Nuova password",
    "确认新密码": "Conferma nuova password",
    "保存修改": "Salva modifiche",
    "充值": "Ricarica",
    "复制": "Copia",
    "分享链接": "Condividi link",
    "升级": "Aggiornamento",
    "登录时间": "Orario di accesso",
    "信誉分": "Credibilità",
    "提现": "Prelievo",
    "提现账号": "Conto di prelievo",
    "你确定要删除此账号吗": "Sei sicuro di voler eliminare questo account?",
    "你确定要删除此地址吗": "Sei sicuro di voler eliminare questo indirizzo?",
    "提示": "Suggerimento",
    "是": "Sì",
    "否": "No",
    "全部": "Tutto",
    "待处理": "In attesa di elaborazione",
    "冻结中": "Congelato",
    "已完成": "Completato",
    "我们的伙伴": "I nostri partner",
    "关于我们": "Chi siamo",
    "创建时间": "Data di creazione",
    "你确定要登出当前账号吗？": "Sei sicuro di voler effettuare il logout dall'account corrente?",
    "正在寻找酒店": "Cercando un hotel",
    "旅程价格": "Prezzo del viaggio",
    "佣金": "Commissioni",
    "搜索": "Cerca",
    "评分": "Valutazione",
    "评论": "Commento",
    "评论-t2": "Recensione",
    "你还没有设置支付密码，请前往设置": "Non hai ancora impostato una password di pagamento, vai alle impostazioni",
    "请输入": "Inserisci qui",
    "请选择": "Seleziona qui",
    "账户明细": "Dettagli dell'account",
    "等级": "Livello",
    "每日订单": "Ordini giornalieri",
    "设置": "Impostazioni",
    "语言设置": "Impostazioni lingua",
    "客服": "Servizio clienti",
    "客服2": "Servizio",
    "投诉": "Reclamo",
    "投诉客户服务": "Reclama il servizio clienti",
    "帮助": "Aiuto",
    "复制成功": "Copia riuscita",
    "位置、中心": "Posizione, centro",
    "你好": "Ciao",
    "我的账户": "Il mio account",
    "头像": "Avatar",
    "昵称": "Soprannome",
    "设置昵称": "Imposta soprannome",
    "输入酒店": "Inserisci l'avenue",
    "住宿问答": "Testimonianze",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Controlla le nostre autentiche testimonianze dai nostri utenti.",
    "酒店问答_Q1": "Qual è la differenza tra una camera doppia e una camera matrimoniale?",
    "酒店问答_A1": "Una camera doppia ha un letto matrimoniale e una camera matrimoniale ha 2 letti singoli. Se una camera è chiamata Doppia / Matrimoniale, può essere allestita per entrambi i tipi. Questa informazione è stata utile?",
    "酒店问答_Q2": "Cosa include il prezzo?",
    "酒店问答_A2": "Puoi verificare se sono inclusi altri addebiti come la colazione, le tasse o le spese di servizio. Queste informazioni possono anche essere trovate nella tua email di conferma dopo la prenotazione e puoi anche visualizzare la tua prenotazione nel tuo account.",
    "立即预订可享受酒店特别折扣": "Esplora ora con tranquillità",
    "最低": "MIN",
    "折扣": "Sconto",
    "订单时间": "Tempo dell'ordine",
    "产品": "Prodotto",
    "订单数量": "Numero dell'ordine",
    "提交订单": "Invia ordine",
    "可用余额": "Saldo disponibile",
    "冻结金额": "Importo bloccato",
    "佣金比例": "Tasso di commissione",
    "获得佣金": "Guadagno di commissione",
    "完成订单": "Completa ordine",
    "请至少选择一项": "Seleziona almeno una opzione",
    "请先选择星星": "Seleziona prima la stella",
    "公告": "Avviso",
    "邀请好友": "Invita amici",
    "邀请好友赚取佣金": "Invita amici e guadagna commissioni",
    "条款&协议": "Termini e accordi",
    "注册协议": "Accordo di registrazione",
    "我已阅读并同意": "Ho letto e accetto",
    "加载中...": "Caricamento in corso...",
    "没有更多数据": "Nessun altro dato",
    "信息": "Messaggio",
    "快乐的客户": "Cliente felice",
    "快乐的酒店老板": "Proprietario felice dell'hotel",
    "评论1": "Piattaforma incredibile con ottime funzionalità, vale davvero la pena esplorare!",
    "评论2": "Servizio clienti proattivo che ti guiderà se non sei chiaro sulla piattaforma.",
    "评论3": "Piattaforma affidabile, vale la pena provare!",
    "评论4": "Sono riuscito a elencare il mio hotel qui e ottenere ottimi risultati di marketing.",
    "客户评价": "Recensioni dei clienti",
    "查看我们用户的真实评价。": "Controlla le recensioni reali dei nostri utenti.",
    "必须五星才能提交订单": "Devi avere cinque stelle per inviare un ordine",
}