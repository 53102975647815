export default {
    "title": "🇨🇳 中文简体",

    "很棒的住宿体验": "很棒的住宿体验",
    "清爽干净，维护良好": "清爽干净，维护良好",
    "很棒的酒店和员工": "很棒的酒店和员工",
    "价值符合预期": "价值符合预期",
    "卓越的": "卓越的",
    "美丽的土地，周末度假的好去处。 有趣的地方": "美丽的土地，周末度假的好去处。 有趣的地方",

    "首页": "首页",
    "预订": "预订",
    "关于": "关于",
    "发现": "发现",
    "会员": "会员",
    "推荐计划": "推荐计划",
    "我的": "我的",
    "个人": "个人",
    "推荐": "推荐",
    "事件": "事件",
    "登出": "登出",
    "登录": "登录",
    "注册": "注册",
    "语言": "语言",
    "忘记密码": "忘记密码",
    "取消": "取消",
    "确认": "确认",
    "酒店": "酒店",
    "银行卡列表": "银行卡列表",
    "银行列表": "银行列表",
    "持卡人姓名": "持卡人姓名",
    "银行卡号": "银行卡号",
    "支付密码": "支付密码",
    "提交": "提交",
    "Pix列表": "Pix列表",
    "Pix类型": "Pix类型",
    "Pix账号": "Pix账号",
    "账号列表": "账号列表",
    "网络": "网络",
    "地址": "地址",
    "备注": "备注",
    "充值金额": "充值金额",
    "支付类型": "支付类型",
    "支付银行": "支付银行",
    "USDT地址": "USDT地址",
    "二维码": "二维码",
    "充值渠道": "充值渠道",
    "充值凭证": "充值凭证",
    "立即登录": "立即登录",
    "用户名": "用户名",
    "密码": "密码",
    "邀请码": "邀请码",
    "确认密码": "确认密码",
    "手机": "手机",
    "金额": "金额",
    "新增": "新增",
    "钱包余额": "账户余额",
    "单价": "单价",
    "数量": "数量",
    "总额": "总额",
    "奖励": "奖励",
    "手机号码": "手机号码",
    "修改密码": "修改密码",
    "登录密码": "登录密码",
    "当前密码": "当前密码",
    "新密码": "新密码",
    "确认新密码": "确认新密码",
    "保存修改": "保存修改",
    "充值": "充值",
    "复制": "复制",
    "分享链接": "分享链接",
    "升级": "升级",
    "登录时间": "登录时间",
    "信誉分": "信誉分",
    "提现": "提现",
    "提现账号": "提现账号",
    "你确定要删除此账号吗": "你确定要删除此账号吗",
    "你确定要删除此地址吗": "你确定要删除此地址吗",
    "提示": "提示",
    "是": "是",
    "否": "否",
    "全部": "全部",
    "待处理": "待处理",
    "冻结中": "冻结中",
    "已完成": "已完成",
    "我们的伙伴": "我们的伙伴",
    "关于我们": "关于我们",
    "创建时间": "创建时间",
    "你确定要登出当前账号吗？": "你确定要登出当前账号吗？",
    "正在寻找酒店": "正在寻找酒店",
    "旅程价格": "旅程价格",
    "佣金": "佣金",
    "搜索": "搜索",
    "评分": "评分",
    "评论": "评论",
    "评论-t2": "评论",
    "你还没有设置支付密码，请前往设置": "你还没有设置支付密码，请前往设置",
    "请输入": "请输入",
    "请选择": "请选择",
    "账户明细": "账户明细",
    "等级": "等级",
    "每日订单": "每日订单",
    "设置": "设置",
    "语言设置": "语言设置",
    "客服": "客服",
    "客服2": "客服",
    "投诉": "投诉",
    "投诉客户服务": "投诉客户服务",
    "帮助": "帮助",
    "复制成功": "复制成功",
    "位置、中心": "位置、中心",
    "你好": "你好",
    "我的账户": "我的账户",
    "头像": "头像",
    "昵称": "昵称",
    "设置昵称": "设置昵称",
    "输入酒店": "输入酒店",
    "住宿问答": "住宿问答",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "如果您想了解更多住宿信息，请查看其他房客的问题",
    "酒店问答_Q1": "双人房和双床房有什么区别？",
    "酒店问答_A1": "双人间配有一张双人床，双床间配有两张单人床。 如果房间称为双人间/双床间，则可以设置为任一类型。 这则信息有帮助吗？",
    "酒店问答_Q2": "价格包含什么？",
    "酒店问答_A2": "您可以查看是否包含其他费用，例如早餐、税费或服务费。 这些信息也可以在您预订后的确认电子邮件中找到，您也可以在您的帐户中查看您的预订。",
    "立即预订可享受酒店特别折扣": "立即预订可享受酒店特别折扣",
    "最低": "最低",
    "折扣": "折扣",
    "订单时间": "订单时间",
    "产品": "产品",
    "订单数量": "订单数量",
    "提交订单": "提交订单",
    "可用余额": "可用余额",
    "冻结金额": "冻结金额",
    "佣金比例": "佣金比例",
    "获得佣金": "获得佣金",
    "完成订单": "完成订单",
    "请至少选择一项": "请至少选择一项",
    "请先选择星星": "请先选择星星",
    "公告": "公告",
    "邀请好友": "邀请好友",
    "邀请好友赚取佣金": "邀请好友赚取佣金",
    "条款&协议": "条款&协议",
    "注册协议": "注册协议",
    "我已阅读并同意": "我已阅读并同意",
    "加载中...": "加载中...",
    "没有更多数据": "没有更多数据",
    "信息": "信息",
    "快乐的客户": "快乐的客户",
    "快乐的酒店老板": "快乐的酒店老板",
    "评论1": "令人惊叹的平台，功能强大，确实值得探索！",
    "评论2": "如果您对该平台不清楚，主动的客户服务将指导您。",
    "评论3": "值得信赖的平台，值得一试！",
    "评论4": "设法在这里列出我的酒店并取得良好的营销效果。",
    "客户评价": "客户评价",
    "查看我们用户的真实评价。": "查看我们用户的真实评价。",
    "必须五星才能提交订单": "必须五星才能提交订单",
    // "aaa": "vvv",
}