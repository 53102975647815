export default {
    "title": "🇪🇸 Español",

    "很棒的住宿体验": "¡Este es un gran lugar para pasear, con muchos lugares que vale la pena ver!",
    "清爽干净，维护良好": "¡Un gran lugar para vacaciones y relajación!",
    "很棒的酒店和员工": "¡Esta es una experiencia diferente y se siente genial!",
    "价值符合预期": "Valor como se esperaba",
    "卓越的": "Excepcional",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Hermosa tierra, ¡un gran lugar para una escapada de fin de semana! Lugar interesante",

    "首页": "Inicio",
    "预订": "RESERVAR",
    "关于": "Acerca de",
    "发现": "Descubrir",
    "会员": "Membresía",
    "推荐计划": "Programa de Referencia",
    "个人": "Perfil",
    "我的": "Mío",
    "推荐": "Referencia",
    "事件": "Evento",
    "登出": "Cerrar sesión",
    "登录": "Iniciar sesión",
    "注册": "Registrarse",
    "语言": "Idioma",
    "忘记密码": "Olvidé",
    "取消": "Cancelar",
    "确认": "Confirmar",
    "酒店": "Destinos destacados",
    "银行卡列表": "Lista de tarjetas bancarias",
    "银行列表": "Lista de bancos",
    "持卡人姓名": "Nombre del titular de la tarjeta",
    "银行卡号": "Número de cuenta bancaria",
    "支付密码": "Contraseña de pago",
    "提交": "Enviar",
    "Pix列表": "Lista de Pix",
    "Pix类型": "Tipo de Pix",
    "Pix账号": "Cuenta de Pix",
    "账号列表": "Lista de cuentas",
    "网络": "Red",
    "地址": "Dirección",
    "备注": "Nota",
    "充值金额": "Monto de recarga",
    "支付类型": "Tipo de pago",
    "支付银行": "Banco de pago",
    "USDT地址": "Dirección USDT",
    "二维码": "Código QR",
    "充值渠道": "Canal de recarga",
    "充值凭证": "Comprobante de recarga",
    "立即登录": "Iniciar sesión ahora",
    "用户名": "Nombre de usuario",
    "密码": "Contraseña",
    "邀请码": "Código de invitación",
    "确认密码": "Confirmar contraseña",
    "手机": "Móvil",
    "金额": "Monto",
    "新增": "Nuevo",
    "钱包余额": "Saldo de cuenta",
    "单价": "Precio",
    "数量": "Cantidad",
    "总额": "Total",
    "奖励": "Recompensas",
    "手机号码": "Número de teléfono",
    "修改密码": "Cambiar contraseña",
    "登录密码": "Contraseña de inicio de sesión",
    "当前密码": "Contraseña actual",
    "新密码": "Nueva contraseña",
    "确认新密码": "Confirmar nueva contraseña",
    "保存修改": "Guardar cambios",
    "充值": "Depositar",
    "复制": "Copiar",
    "分享链接": "Compartir enlace",
    "升级": "Actualizar",
    "登录时间": "Hora de inicio de sesión",
    "信誉分": "Credibilidad",
    "提现": "Retirar",
    "提现账号": "Cuenta de retiro",
    "你确定要删除此账号吗": "¿Estás seguro de que quieres eliminar esta cuenta?",
    "你确定要删除此地址吗": "¿Estás seguro de que quieres eliminar esta dirección?",
    "提示": "Consejo",
    "是": "Sí",
    "否": "No",
    "全部": "Todo",
    "待处理": "Pendiente",
    "冻结中": "Congelado",
    "已完成": "Completado",
    "我们的伙伴": "Nuestros socios",
    "关于我们": "SOBRE NOSOTROS",
    "创建时间": "Hora de creación",
    "你确定要登出当前账号吗？": "¿Estás seguro de que quieres cerrar sesión en la cuenta actual?",
    "正在寻找酒店": "Buscando un hotel",
    "旅程价格": "Precio del viaje",
    "佣金": "Comisiones",
    "搜索": "Buscar",
    "评分": "Calificación",
    "评论": "Comentario",
    "评论-t2": "Reseña",
    "你还没有设置支付密码，请前往设置": "Aún no has establecido una contraseña de pago, por favor ve a configuración",
    "请输入": "Escriba aquí",
    "请选择": "Seleccione aquí",
    "账户明细": "Detalles de la cuenta",
    "等级": "Nivel",
    "每日订单": "Pedidos diarios",
    "设置": "Configuración",
    "语言设置": "Configuración de idioma",
    "客服": "Servicio al cliente",
    "客服2": "Servicio",
    "投诉": "Quejas",
    "投诉客户服务": "Quejarse del servicio al cliente",
    "帮助": "Ayuda",
    "复制成功": "Copiado exitoso",
    "位置、中心": "Ubicación, centro",
    "你好": "Hola",
    "我的账户": "Mi cuenta",
    "头像": "Avatar",
    "昵称": "Apodo",
    "设置昵称": "Establecer apodo",
    "输入酒店": "Ingrese avenida",
    "住宿问答": "Testimonios",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Consulte nuestros testimonios auténticos de nuestros usuarios.",
    "酒店问答_Q1": "¿Cuál es la diferencia entre una habitación doble y una habitación twin?",
    "酒店问答_A1": "Una habitación doble tiene una cama doble y una habitación twin tiene 2 camas individuales. Si una habitación se llama Doble/Twin, puede configurarse para cualquiera de los dos tipos. ¿Fue útil esta información?",
    "酒店问答_Q2": "¿Qué incluye el precio?",
    "酒店问答_A2": "Puede verificar si se incluyen otros cargos como desayuno, impuestos o cargos por servicio. Esta información también se puede encontrar en su correo electrónico de confirmación después de reservar, y también puede ver su reserva en su cuenta.",
    "立即预订可享受酒店特别折扣": "Explore ahora con tranquilidad",
    "最低": "MÍN",
    "折扣": "DESC",
    "订单时间": "Hora del pedido",
    "产品": "Producto",
    "订单数量": "Número de pedido",
    "提交订单": "Enviar pedido",
    "可用余额": "Saldo disponible",
    "冻结金额": "Monto congelado",
    "佣金比例": "Tasa de comisión",
    "获得佣金": "Ganancia de comisión",
    "完成订单": "Completar pedido",
    "请至少选择一项": "Seleccione al menos una opción",
    "请先选择星星": "Seleccione la estrella primero",
    "公告": "Aviso",
    "邀请好友": "Invitar amigos",
    "邀请好友赚取佣金": "Invite amigos y gane comisiones",
    "条款&协议": "Términos y acuerdos",
    "注册协议": "Acuerdo de registro",
    "我已阅读并同意": "He leído y acepto",
    "加载中...": "Cargando...",
    "没有更多数据": "No hay más datos",
    "信息": "Mensaje",
    "快乐的客户": "Cliente feliz",
    "快乐的酒店老板": "Propietario de hotel feliz",
    "评论1": "¡Plataforma increíble con excelentes características, realmente vale la pena explorar!",
    "评论2": "Servicio al cliente proactivo que lo guiará si no está claro acerca de la plataforma.",
    "评论3": "¡Plataforma confiable, vale la pena intentarlo!",
    "评论4": "Logré listar mi hotel aquí y lograr excelentes resultados de marketing.",
    "客户评价": "Opiniones de los clientes",
    "查看我们用户的真实评价。": "Consulte las opiniones reales de nuestros usuarios.",
    "必须五星才能提交订单": "Debe tener cinco estrellas para enviar un pedido.",
}