<script setup>
import { nextTick, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { isAuthRef } from '@/model/user'
import UiFooter from "./components/UiFooter.vue"
import WiToast from "./components/WiToast/toast.vue"
import WiDialog from "./components/WiDialog/dialog.vue"

const route = useRoute()
const router = useRouter()

const APPNAME = process.env.VUE_APP_NAME

watch(()=> isAuthRef.value, (newx)=> {
  if(!newx && route.meta.loginRequired) {
    router.replace({
      path: '/login',
      query: {
        next: encodeURI(route.fullPath)
      }
    })
  }
}, {immediate: true, deep: true})


onMounted(()=> {
  nextTick(()=> {
    document.title = APPNAME
  })
})
</script>

<template>
  <!-- <div class="min-h-screen flex flex-col">
    <router-view class="grow" />
    <UiFooter v-if="route.meta.tabar"></UiFooter>
  </div>
  <WiDialog></WiDialog>
  <WiToast></WiToast> -->
  <div id="app" class="min-h-screen flex flex-col">
    <router-view class="grow" />
    <UiFooter v-if="route.meta.tabar"></UiFooter>
    <WiDialog></WiDialog>
    <WiToast></WiToast>
  </div>
</template>