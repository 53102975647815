export default {
    "title": "🇩🇰 Dansk",

    "很棒的住宿体验": "Dette er et fantastisk sted at vandre rundt, med mange steder værd at se.",
    "清爽干净，维护良好": "Et fantastisk sted til ferie og afslapning!",
    "很棒的酒店和员工": "Dette er en anderledes oplevelse, og det føles fantastisk",
    "价值符合预期": "Værdi som forventet",
    "卓越的": "Fremragende",
    "美丽的土地，周末度假的好去处。 有趣的地方": "Smukke land, et fantastisk sted for en weekend getaway. Interessant sted",

    "首页": "Hjem",
    "预订": "RESERVER",
    "关于": "Om",
    "发现": "Opdag",
    "会员": "Medlemskab",
    "推荐计划": "Henvisningsprogram",
    "个人": "Profil",
    "我的": "Min",
    "推荐": "Henvisning",
    "事件": "Begivenhed",
    "登出": "Log ud",
    "登录": "Log ind",
    "注册": "Registrer",
    "语言": "Sprog",
    "忘记密码": "Glemt",
    "取消": "Afbestille",
    "确认": "Bekræft",
    "酒店": "Spotlight Destinationer",
    "银行卡列表": "Bankkortliste",
    "银行列表": "Bankliste",
    "持卡人姓名": "Kortholders navn",
    "银行卡号": "Bankkonto",
    "支付密码": "Betalingskodeord",
    "提交": "Indsend",
    "Pix列表": "Pix Liste",
    "Pix类型": "Pix Type",
    "Pix账号": "Pix Konto",
    "账号列表": "Konto Liste",
    "网络": "Netværk",
    "地址": "Adresse",
    "备注": "Bemærk",
    "充值金额": "Genopladningsbeløb",
    "支付类型": "Betalings Type",
    "支付银行": "Betalingsbank",
    "USDT地址": "USDT Adresse",
    "二维码": "QR Kode",
    "充值渠道": "Genopladningskanal",
    "充值凭证": "Genopladningsbevis",
    "立即登录": "Log ind nu",
    "用户名": "Brugernavn",
    "密码": "Adgangskode",
    "邀请码": "Invitationskode",
    "确认密码": "Bekræft adgangskode",
    "手机": "Mobil",
    "金额": "Beløb",
    "新增": "Ny",
    "钱包余额": "Konto Balance",
    "单价": "Pris",
    "数量": "Antal",
    "总额": "Total",
    "奖励": "Belønninger",
    "手机号码": "Telefonnummer",
    "修改密码": "Ændre adgangskode",
    "登录密码": "Log ind adgangskode",
    "当前密码": "Nuværende adgangskode",
    "新密码": "Ny adgangskode",
    "确认新密码": "Bekræft ny adgangskode",
    "保存修改": "Gem ændringer",
    "充值": "Indsæt penge",
    "复制": "Kopier",
    "分享链接": "Del link",
    "升级": "Opgradér",
    "登录时间": "Log ind tid",
    "信誉分": "Kreditværdighed",
    "提现": "Hæv penge",
    "提现账号": "Hævekonto",
    "你确定要删除此账号吗": "Er du sikker på, at du vil slette denne konto",
    "你确定要删除此地址吗": "Er du sikker på, at du vil slette denne adresse",
    "提示": "Tip",
    "是": "Ja",
    "否": "Nej",
    "全部": "Alle",
    "待处理": "Afventer behandling",
    "冻结中": "Frosset",
    "已完成": "Fuldført",
    "我们的伙伴": "Vores partnere",
    "关于我们": "OM OS",
    "创建时间": "Oprettet tid",
    "你确定要登出当前账号吗？": "Er du sikker på, at du vil logge ud af den nuværende konto?",
    "正在寻找酒店": "Søger efter hotel",
    "旅程价格": "Rejsepris",
    "佣金": "Provision",
    "搜索": "Søg",
    "评分": "Bedømmelse",
    "评论": "Kommentar",
    "评论-t2": "Anmeldelse",
    "你还没有设置支付密码，请前往设置": "Du har ikke indstillet en betalingsadgangskode, gå venligst til indstillinger",
    "请输入": "Indtast venligst",
    "请选择": "Vælg venligst",
    "账户明细": "Kontooplysninger",
    "等级": "Niveau",
    "每日订单": "Daglige ordrer",
    "设置": "Indstillinger",
    "语言设置": "Sprogindstillinger",
    "客服": "Kundeservice",
    "客服2": "Service",
    "投诉": "Klage",
    "投诉客户服务": "Klage over kundeservice",
    "帮助": "Hjælp",
    "复制成功": "Kopiering vellykket",
    "位置、中心": "Beliggenhed, center",
    "你好": "Hej",
    "我的账户": "Min konto",
    "头像": "Avatar",
    "昵称": "Kaldenavn",
    "设置昵称": "Indstil kaldenavn",
    "输入酒店": "Indtast hotel",
    "住宿问答": "Anmeldelser",
    "如果您想了解更多住宿信息，请查看其他房客的问题": "Se vores autentiske anmeldelser fra vores brugere.",
    "酒店问答_Q1": "Hvad er forskellen mellem et dobbeltværelse og et twin-værelse?",
    "酒店问答_A1": "Et dobbeltværelse har en dobbeltseng og et twin-værelse har 2 enkeltsenge. Hvis et værelse kaldes Double/Twin, kan det indstilles til begge typer. Var denne information nyttig?",
    "酒店问答_Q2": "Hvad inkluderer prisen?",
    "酒店问答_A2": "Du kan tjekke, om andre gebyrer som morgenmad, skatter eller servicegebyrer er inkluderet. Denne information kan også findes i din bekræftelses-e-mail efter booking, og du kan også se din reservation i din konto.",
    "立即预订可享受酒店特别折扣": "Udforsk nu med ro i sindet",
    "最低": "MIN",
    "折扣": "RABAT",
    "订单时间": "Ordretid",
    "产品": "Produkt",
    "订单数量": "Ordrenummer",
    "提交订单": "Indsend ordre",
    "可用余额": "Frit balance",
    "冻结金额": "Frysebeløb",
    "佣金比例": "Provisionsrate",
    "获得佣金": "Tjen provision",
    "完成订单": "Fuldfør ordre",
    "请至少选择一项": "Vælg mindst en",
    "请先选择星星": "Vælg stjernen først",
    "公告": "Meddelelse",
    "邀请好友": "Inviter venner",
    "邀请好友赚取佣金": "Inviter venner og tjen provision",
    "条款&协议": "Vilkår og betingelser",
    "注册协议": "Registreringsaftale",
    "我已阅读并同意": "Jeg har læst og accepterer",
    "加载中...": "Indlæser...",
    "没有更多数据": "Ingen flere data",
    "信息": "Besked",
    "快乐的客户": "Glad kunde",
    "快乐的酒店老板": "Glad hotel ejer",
    "评论1": "Fantastisk platform med gode funktioner, virkelig værd at udforske!",
    "评论2": "Proaktiv kundeservice, der vil guide dig, hvis du er uklar om platformen.",
    "评论3": "Pålidelig platform, værd at prøve!",
    "评论4": "Formået at liste mit hotel her og opnå stor markedsføringsresultat.",
    "客户评价": "Kunde anmeldelser",
    "查看我们用户的真实评价。": "Se ægte anmeldelser fra vores brugere.",
    "必须五星才能提交订单": "Skal have fem stjerner for at indsende en ordre",
}